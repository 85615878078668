import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"checkpoint__continue\">\n  <PixButtonLink\n    @route=\"assessments.resume\"\n    @model={{@assessmentId}}\n    @query={{this.query}}\n    @backgroundColor={{@backgroundColor}}\n    class=\"checkpoint__continue-button\"\n  >\n    {{@nextPageButtonText}}\n    <FaIcon @icon=\"right-long\" />\n  </PixButtonLink>\n</div>", {"contents":"<div class=\"checkpoint__continue\">\n  <PixButtonLink\n    @route=\"assessments.resume\"\n    @model={{@assessmentId}}\n    @query={{this.query}}\n    @backgroundColor={{@backgroundColor}}\n    class=\"checkpoint__continue-button\"\n  >\n    {{@nextPageButtonText}}\n    <FaIcon @icon=\"right-long\" />\n  </PixButtonLink>\n</div>","moduleName":"mon-pix/components/checkpoint-continue.hbs","parseOptions":{"srcName":"mon-pix/components/checkpoint-continue.hbs"}});
import Component from '@glimmer/component';

export default class CheckpointContinue extends Component {
  get query() {
    return {
      assessmentHasNoMoreQuestions: this.args.finalCheckpoint,
      hasSeenCheckpoint: true,
    };
  }
}
