import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"background-banner-wrapper\" role=\"banner\">\n  <div class=\"background-banner\"></div>\n  <div class=\"rounded-panel rounded-panel--strong rounded-panel--over-background-banner\">\n    <div class=\"campaign-landing-page__start__image__container\">\n      <div class=\"campaign-landing-page__pix-logo\">\n        <img class=\"campaign-landing-page__image\" src=\"/images/brix-logo.svg\" alt=\"{{t 'navigation.pix'}}\" />\n      </div>\n    </div>\n    <div class=\"campaign-landing-page__error-text\">\n      <p class=\"campaign-landing-page__error-text title\">{{yield}}</p>\n      <LinkTo @route=\"authenticated\" class=\"button button--extra-big button--link campaign-landing-page__error-button\">\n        {{t \"navigation.back-to-profile\"}}\n      </LinkTo>\n    </div>\n  </div>\n</div>", {"contents":"<div class=\"background-banner-wrapper\" role=\"banner\">\n  <div class=\"background-banner\"></div>\n  <div class=\"rounded-panel rounded-panel--strong rounded-panel--over-background-banner\">\n    <div class=\"campaign-landing-page__start__image__container\">\n      <div class=\"campaign-landing-page__pix-logo\">\n        <img class=\"campaign-landing-page__image\" src=\"/images/brix-logo.svg\" alt=\"{{t 'navigation.pix'}}\" />\n      </div>\n    </div>\n    <div class=\"campaign-landing-page__error-text\">\n      <p class=\"campaign-landing-page__error-text title\">{{yield}}</p>\n      <LinkTo @route=\"authenticated\" class=\"button button--extra-big button--link campaign-landing-page__error-button\">\n        {{t \"navigation.back-to-profile\"}}\n      </LinkTo>\n    </div>\n  </div>\n</div>","moduleName":"mon-pix/components/inaccessible-campaign.hbs","parseOptions":{"srcName":"mon-pix/components/inaccessible-campaign.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class InaccessibleCampaign extends Component {
  @service url;

  get shouldShowTheMarianneLogo() {
    return this.url.isFrenchDomainExtension;
  }
}
