export default function () {
  return {
    data: {
      type: 'email-verification-codes',
      attributes: {
        email: 'new-email@example.net',
      },
    },
  };
}
